var ALL_LANGS;

AOS.init({
  once: true,
  duration: 600,
  offset: 250,
  delay: 200
});

$(document).ready(function () {
  $.extend($.scrollTo.defaults, {
    axis: 'y',
    duration: 500,
    interrupt: true
  });

  // Get last selected language or the browser configured language
  var userLang = window.localStorage.getItem('lang-code') || (navigator.language || navigator.userLanguage);

  var translator;
  ALL_LANGS = $('.fixed-menu .lang-menu a').map(function () {
    return $(this).attr('rel')
  }).toArray();

  setPageTranslation(userLang);

  setAboutAccordions();

  $('.fixed-menu .lang-menu a, .overlay.menu .content .lang-menu a').click(function () {
    var langCode = $(this).attr('rel');
    translator.lang(langCode);
    setPageTranslation(langCode);
    localStorage.setItem('lang-code', langCode);
  });

  function setLangMenuActive (langCode) {
    $('.fixed-menu .lang-menu a, .overlay.menu .lang-menu a').removeClass('inactive').addClass('inactive');
    $(".fixed-menu .lang-menu a[rel='" + langCode + "'], .overlay.menu .lang-menu a[rel='" + langCode + "']").removeClass('inactive');
  }

  function setPageTranslation (currentLanguage) {
    var code = getCorrectLanguageCode(currentLanguage);

    setLangMenuActive(code);

    setPageLanguage(code);

    translator = $('body').translate({ lang: code, t: SITE_TRANSLATION });
  }

  function setPageLanguage (code) {
    $('html').attr('lang', code);
  }

  // Main menu, fixed menu navigation
  $('.goto-menu, .fixed-menu .menu-list a:not(.toogle-menu, .external-link), .overlay.menu .content .menu-list a:not(.external-link)').on('click', function (e) {
    e.preventDefault()

    var overlay = $(this).parents('.overlay');

    if (window.location.href.replace(window.location.origin, '').indexOf('#') < 2) {
      document.location.href = window.location.origin + $(this).attr('href');
    }

    // If menu is being triggered from inside of the overlay
    if (overlay.length) {
      overlay.find('.overlay-close').trigger('click');
    }

    const sectionClass = $(this).attr('href').substring(1);

    $(window).scrollTo($('.' + sectionClass));
  });

  var siteSections = $('.fixed-menu .menu-list a:not(.toogle-menu, .external-link)').map(function () {
    const item = $('section.' + $(this).attr('href').substring(1));
    if (item.length) { return item.get(0); }
  });

  const topMenuHeight = $('.fixed-menu').outerHeight() + 1;

  let lastScrollPosition = 0;

  // Site scrolling control
  $(window).scroll(function () {
    var scrollPos = $(window).scrollTop();
    var homeHeight = $('section.home').height();
    var fixedMenu = $('.fixed-menu');

    // Show fixed menu, only going up after the home section
    if (scrollPos > (homeHeight / 2) && scrollPos < lastScrollPosition) {
      fixedMenu.addClass('active');
    } else {
      fixedMenu.removeClass('active');
    }

    lastScrollPosition = scrollPos;

    var fromTop = scrollPos + topMenuHeight;

    // Current Menu scrollPost + menuHeight
    var currentMenu = getCurrentMenu(fromTop, siteSections);

    if (currentMenu) {
      setActiveMenu(currentMenu);
    } else {
      unsetActiveMenu();
    }
  });

  // Popups
  $('a.open-overlay').on('click', showPopup);
  $('.overlay .overlay-close').on('click', closePopup);

  // Portfolio
  $('.bg-hover').click(function () {
    var overlayId = $(this).data('overlay');
    if (overlayId) {
      var portfolioList = $('section.portfolio .portfolio-list');
      var overlay = $('#' + overlayId + '-overlay');
      $(window).scrollTo($('section.portfolio h2'));

      portfolioList.find('.cases').fadeOut(function () {
        overlay.slideDown();
      });
      overlay.on('click', '.close', function () {
        overlay.fadeOut(function () {
          portfolioList.find('.cases').fadeIn();
        });
      });

      var slickEl = overlay.find('.slick');
      if (!slickEl.hasClass('slick-initialized')) {
        slickEl.slick({
          lazyLoad: 'progressive',
          variableWidth: true,
          appendArrows: overlay.find('.nav'),
          prevArrow: '<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56"><defs><filter id="A" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse"><feOffset dy="6"/><feGaussianBlur stdDeviation="3" result="A"/><feFlood flood-opacity=".16"/><feComposite operator="in" in2="A"/><feComposite in="SourceGraphic"/></filter></defs><g transform="translate(9 3)"><g transform="translate(-9 -3)" filter="url(#A)"><g transform="translate(9 3)" fill="#fff"><path d="M19 37.25a18.13 18.13 0 0 1-12.905-5.345A18.13 18.13 0 0 1 .75 19 18.13 18.13 0 0 1 6.095 6.095 18.13 18.13 0 0 1 19 .75a18.13 18.13 0 0 1 12.905 5.345A18.13 18.13 0 0 1 37.25 19a18.13 18.13 0 0 1-5.345 12.905A18.13 18.13 0 0 1 19 37.25z"/><path d="M19 1.5c-4.674 0-9.07 1.82-12.374 5.126S1.5 14.326 1.5 19s1.82 9.07 5.126 12.374S14.326 36.5 19 36.5s9.07-1.82 12.374-5.126S36.5 23.674 36.5 19s-1.82-9.07-5.126-12.374S23.674 1.5 19 1.5M19 0c10.493 0 19 8.507 19 19s-8.507 19-19 19S0 29.493 0 19 8.507 0 19 0z" fill="#7058ff" class="colored" /></g></g><path d="M20.432 25.467l3.042-3.04L20.047 19l3.427-3.425-2.98-2.98-6.53 6.43 6.467 6.444z"  class="colored" fill="#7058ff"/></g></svg>',
          nextArrow: '<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56"><defs><filter id="A" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse"><feOffset dy="6"/><feGaussianBlur stdDeviation="3" result="A"/><feFlood flood-opacity=".16"/><feComposite operator="in" in2="A"/><feComposite in="SourceGraphic"/></filter></defs><g transform="translate(9 3)"><g transform="translate(-9 -3)" filter="url(#A)"><g transform="translate(9 3)" fill="#fff"><path d="M19 37.25a18.13 18.13 0 0 1-12.905-5.345A18.13 18.13 0 0 1 .75 19 18.13 18.13 0 0 1 6.095 6.095 18.13 18.13 0 0 1 19 .75a18.13 18.13 0 0 1 12.905 5.345A18.13 18.13 0 0 1 37.25 19a18.13 18.13 0 0 1-5.345 12.905A18.13 18.13 0 0 1 19 37.25z"/><path d="M19 1.5c-4.674 0-9.07 1.82-12.374 5.126S1.5 14.326 1.5 19s1.82 9.07 5.126 12.374S14.326 36.5 19 36.5s9.07-1.82 12.374-5.126S36.5 23.674 36.5 19s-1.82-9.07-5.126-12.374S23.674 1.5 19 1.5M19 0c10.493 0 19 8.507 19 19s-8.507 19-19 19S0 29.493 0 19 8.507 0 19 0z" fill="#7058ff" class="colored" /></g></g><path d="M17.568 25.467l-3.042-3.04L17.953 19l-3.427-3.426 2.98-2.98 6.528 6.43-6.467 6.444z"  class="colored" fill="#7058ff"/></g></svg>'
        });
      }
    }
  })
  $('.bg-hover').each(function () {
    let element = $(this);
    let red = element.data('rgb-red')
    let green = element.data('rgb-green')
    let blue = element.data('rgb-blue')

    element.hover(function () {
      element.before().css('transition', 'background-color 0.5s ease-in-out');
      element.before().css('background-color', `rgba(${red},${green},${blue},.7)`);
    }, function () {
      element.before().css('transition', 'background-color 0.5s ease-in-out');
      element.before().css('background-color', `rgba(${red},${green},${blue},0)`);
    });
  })
});

// Get the current menu based on the scroll position
function getCurrentMenu (fromTop, siteSections) {
  var current = false;
  $(siteSections.get().reverse()).each(function () {
    if ($(this).offset().top < fromTop) {
      current = $(this);
      return false;
    }
  })
  return current;
}

var activeClass = 'active';

// Receives the section element
function setActiveMenu (sectionEl) {
  var hrefSelect = '#' + sectionEl.attr('class');

  $('.fixed-menu a').removeClass(activeClass);
  $(".fixed-menu a[href='" + hrefSelect + "']").addClass(activeClass);
}

// Remove active state from all menus itens
function unsetActiveMenu () {
  $('.fixed-menu a').removeClass(activeClass);
}

// Show Service Popup
function showPopup () {
  $('body').addClass('no-overflow');
  var className = $(this).attr('href').substring(1);

  var overlay = $('.overlay.' + className);

  if (overlay.length) {
    overlay.addClass('open');
  }
}

function closePopup () {
  $('body').removeClass('no-overflow');
  var overlay = $(this).parents('.overlay');

  overlay.removeClass('open').addClass('close');

  setTimeout(function () {
    overlay.removeClass('close')
  }, 500);
}

function getCorrectLanguageCode (currentLanguage) {
  var defaultInternationalLang = 'en';

  var langPos = ALL_LANGS.indexOf(currentLanguage);

  // Language not supported, use default
  if (langPos === -1) {
    return defaultInternationalLang;
  } else {
    return ALL_LANGS[langPos];
  }
}

function setAboutAccordions () {
  $('.about .item .btn-plus').click(function (e) {
    e.preventDefault();
    var clickedButton = $(this);
    var idName = clickedButton.attr('href').substring(1);
    var showDetailElement = $('.about .about-detail#' + idName);
    var previousDetailsElement = $('.about .about-detail.active');
    var animationWrapper = $('.about .details-wrapper');
    var firstTimeOut = 0;
    var secondTimeOut = 0;

    clearTimeout(firstTimeOut);
    clearTimeout(secondTimeOut);

    if (clickedButton.hasClass('active')) {
      hideAccordionAnimation(false)
    } else {
      if (animationWrapper.hasClass('open')) {
        hideAccordionAnimation(true);
      } else {
        showAccordionAnimation();
      }
    }

    function hideAccordionAnimation (showAfter) {
      $('.about .item .btn-plus').removeClass('active');

      previousDetailsElement.removeClass('visible');

      firstTimeOut = setTimeout(function () {
        animationWrapper.removeClass('open');
        secondTimeOut = setTimeout(function () {
          previousDetailsElement.removeClass('active');
          if (showAfter) {
            showAccordionAnimation();
          }
        }, 1000);
      }, 500);
    }

    function showAccordionAnimation () {
      animationWrapper.addClass('open');
      $('.about .item .btn-plus').removeClass('active');
      clickedButton.addClass('active');
      $('.about .about-detail').removeClass('active');

      showDetailElement.addClass('active');

      secondTimeOut = setTimeout(function () {
        showDetailElement.addClass('visible');
      }, 1000);

      $(window).scrollTo(showDetailElement);
    }
  });
}
